import { Guest } from "../guest";

const URL = "https://backend.moliclau.com/guests";

export function getGuestByName(name: string, lastName: string): Promise<Response> {
    return fetch(`${URL}?name=${name.trim()}&lastName=${lastName.trim()}`)
}

export function updateGuest(guest: Guest): Promise<Response> {
    return fetch(URL, {
        method: "PUT", 
        body: JSON.stringify(guest), 
        headers: {
            "Content-Type": "application/json"
        }
    })
}