import { Component, ReactNode } from "react";
import "./css/contact.css";

export class Contact extends Component {

    render(): ReactNode {
        return (
            <div id="contactMainContainer" >
                <div id="contactDataContainer">
                    <div id="contact">
                        <div className="horizontalSpaceBetweenLayout">
                            <div id="contactData">
                                <div id="contactTitle">Contacto</div>
                                <div id="contactFields">
                                    <div className="contactField">
                                        <span >Clau: 622827625</span>
                                    </div>
                                    <div className="contactField secondContactField">
                                        <span >Jaime: 644023151</span>
                                    </div>
                                </div>
                            </div>
                            <div id="logoImage"></div>
                        </div>

                        <div id="separator" />
                        <div className="horizontalCenteredLayout" id="byOursText">
                            <div id="copyrightImage" />
                            <span>By Claudia Rios & Jaime Molinero</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}