import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { MenuType } from "./menuType";
import Select, { StylesConfig } from 'react-select';
import "./../css/confirmation.css"
import { ReturnBusSchedule } from "./returnBusSchedule";
import ErrorComponent from "../errorComponent";
import notFoundPicture from "./../pictures/notFound.svg";
import { toMenuType } from "./menuTypeConverter";
import { updateGuest } from "../clients/guestClient";
import { toReturnBusSchedule, toReturnBusScheduleKey, toReturnBusScheduleValue } from "./returnBusScheduleConverter";
import { Guest } from "../guest";
import { HOME_PATH } from "../urls";
import { WeddingModal } from "../modal/weddingModal";


export default function ConfirmationForm() {
    const location = useLocation();

    const [guest] = useState(location.state?.guest);
    const [isFirstTime] = useState(Boolean(!guest?.confirmed))
    const [allergies, setAllergies] = useState(guest?.allergies)
    const [menu, setMenu] = useState(toMenuType(guest?.menu));
    const [menuUpdated, setMenuUpdated] = useState(!isFirstTime);
    const [fromBus, setFromBus] = useState(guest?.fromBus);
    const [fromBusUpdated, setFromBusUpdated] = useState(!isFirstTime);
    const [returnBus, setReturnBus] = useState(guest?.returnBus);
    const [returnBusUpdated, setReturnBusUpdated] = useState(!isFirstTime);
    const [returnBusTime, setReturnBusTime] = useState(toReturnBusSchedule(guest?.returnBusTime));
    const [returnBusTimeUpdated, setReturnBusTimeUpdated] = useState(!isFirstTime);
    const [preWeddingConfirmed, setPreWeddingConfirmed] = useState(guest?.preWeddingConfirmed);
    const [preWeddingCandidate] = useState(guest?.preWeddingCandidate);
    const [preWeddingUpdated, setPreWeddingUpdated] = useState(!isFirstTime);
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)

    if (!guest) {
        console.error("Guest not found")
        return (
            <ErrorComponent errorPicture={{ src: notFoundPicture, height: 204, width: 300 }} errorText="Página o recurso no encontrado" returnButton={{ enabled: false }} />
        )
    }

    const selectorStyles: StylesConfig = {
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#B34764 !important" : isSelected ? "#FFFFFF !important" : "",
                color: isFocused ? "white" : isSelected ? "hsl(0, 0%, 20%)" : "",
                border: "#B34764",
                boxShadow: "#B34764"
            }
        },
        dropdownIndicator: (styles) => {
            return {
                ...styles,
                color: "#000000"
            }
        },
        control: (styles, { isFocused, menuIsOpen }) => {
            return {
                ...styles,
                border: isFocused || menuIsOpen ? "2px solid #B34764 !important" : "",
                boxShadow: ""
            }
        },
    }

    return (
        <div className="pageMainContainer">
            <div className="defaultMargings">
                <div className="defaultTitle">
                    Confirmar asistencia
                </div>
                <div className="defaultDescription">
                    ¡Genial! Tenemos muchísimas ganas de verte en Málaga. Por favor, rellena el siguiente formulario para terminar de confirmar tu asistencia:
                </div>
                {renderPreWedding(guest, {
                    preWeddingConfirmation: preWeddingConfirmed,
                    preWeddingUpdated,
                    setPreWeddingUpdated,
                    setPreWeddingConfirmation: setPreWeddingConfirmed
                }, selectorStyles)}

                <div className="sectionSeparation">
                    <div className="secondaryInputStyle bold">BODA</div>
                    <div>
                        <label className="inputLabel" htmlFor="selectMenu">Selecciona tu menú</label>
                        <Select className="inputSelect" styles={selectorStyles} options={[
                            { value: MenuType.GENERAL, label: MenuType.GENERAL },
                            { value: MenuType.INFANTIL, label: MenuType.INFANTIL },
                            { value: MenuType.VEGANO, label: MenuType.VEGANO }
                        ]} id="menus" name="menus" placeholder="Selecciona tu menú" value={menuUpdated ? { value: menu, label: menu } : null} onChange={(option: any) => {
                            setMenuUpdated(true)
                            setMenu(option.value)
                        }} />
                    </div>
                    <div>
                        <label className="inputLabel" htmlFor="allergies">Especifica tus alergias o intolerancias</label>
                        <textarea className="inputBig" name="allergies" id="allergies" placeholder="Alergias/Intolerancias" value={allergies} onChange={event => setAllergies(event.target.value)} />
                    </div>
                    <div className="sectionSeparation">
                        <div className="secondaryInputStyle">AUTOBÚS IDA</div>
                        <div className="secondaryInputStyle confirmationMarginTop">¿Necesitas ir en autobús desde la iglesia a la finca en Churriana?</div>
                        <div >
                            <div className="confirmationMarginTop radioContainer">
                                <input type="radio" className="secondaryInputStyle radioInput" id="firstSiRadioValue" name="firstBusRadioGroup" value="1" checked={fromBus && fromBusUpdated}
                                    onChange={event => {
                                        setFromBusUpdated(true)
                                        setFromBus(Boolean(Number(event.target.value)))
                                    }} />
                                <label className="secondaryInputStyle radioLabel" htmlFor="firstSiRadioValue">Sí</label>
                            </div>
                            <div className="radioContainer radioSeparation">
                                <input type="radio" className="secondaryInputStyle radioInput" id="firstNoRadioValue" name="firstBusRadioGroup" value="0" checked={!fromBus && fromBusUpdated}
                                    onChange={event => {
                                        setFromBusUpdated(true)
                                        setFromBus(Boolean(Number(event.target.value)))
                                    }} />
                                <label className="secondaryInputStyle radioLabel" htmlFor="firstNoRadioValue">No, iré por mi cuenta</label>
                            </div>
                        </div>

                        <div>
                            <div className="secondaryInputStyle" id="returnBusMarginTop">AUTOBÚS VUELTA</div>
                            <div className="secondaryInputStyle confirmationMarginTop">¿Necesitas ir en autobús desde la finca en Churriana a Málaga centro?</div>
                            <div className="confirmationMarginTop radioContainer">
                                <input type="radio" className="secondaryInputStyle radioInput" id="secondSiRadioValue" name="secondBusRadioGroup" value="1" checked={returnBus && returnBusUpdated} onChange={() => {
                                    if (returnBusTime === ReturnBusSchedule.EMPTY) {
                                        if (toReturnBusSchedule(guest.returnBusTime) === ReturnBusSchedule.EMPTY) {
                                            setReturnBusTime(ReturnBusSchedule.FIRST)
                                        }
                                        else {
                                            setReturnBusTime(toReturnBusSchedule(guest.returnBusTime))
                                        }
                                    }
                                    setReturnBusUpdated(true)
                                    setReturnBus(true)
                                }} />
                                <label className="secondaryInputStyle radioLabel" htmlFor="secondSiRadioValue">Sí</label>
                            </div>
                            <div className="radioContainer radioSeparation">
                                <input type="radio" className="secondaryInputStyle radioInput" id="secondNoRadioValue" name="secondBusRadioGroup" value="0" checked={!returnBus && returnBusUpdated} onChange={() => {
                                    setReturnBusTime(ReturnBusSchedule.EMPTY)
                                    setReturnBusUpdated(true)
                                    setReturnBus(false)
                                }} />
                                <label className="secondaryInputStyle radioLabel" htmlFor="secondNoRadioValue">No, iré por mi cuenta</label>
                            </div>
                        </div>
                    </div>
                    <div hidden={!returnBus}>
                        <label className="inputLabel" htmlFor="selectReturnTime">Selecciona horario aproximado</label>
                        <Select className="inputSelect" styles={selectorStyles} options={[
                            { value: ReturnBusSchedule.FIRST, label: toReturnBusScheduleValue(ReturnBusSchedule.FIRST) },
                            { value: ReturnBusSchedule.SECOND, label: toReturnBusScheduleValue(ReturnBusSchedule.SECOND) },
                            { value: ReturnBusSchedule.LAST, label: toReturnBusScheduleValue(ReturnBusSchedule.LAST) },
                        ]} id="returnHour" name="returnHour" value={returnBusTimeUpdated ? { value: returnBusTime, label: toReturnBusScheduleValue(returnBusTime) } : null} placeholder="Selecciona horario vuelta" onChange={(option: any) => {
                            setReturnBusTimeUpdated(true)
                            setReturnBusTime(option.value)
                        }} />
                    </div>
                    <div id="confirmAssist">
                        <button type="submit" className="buttonPrimary"
                            disabled={!isValidData(menuUpdated, fromBusUpdated, returnBusUpdated, returnBusTimeUpdated, preWeddingUpdated,
                                {
                                    id: guest.id,
                                    name: guest.name,
                                    lastName: guest.lastName,
                                    allergies: allergies,
                                    menu: menu,
                                    fromBus: fromBus,
                                    returnBus: returnBus,
                                    returnBusTime: toReturnBusScheduleKey(returnBusTime),
                                    confirmed: guest.confirmed,
                                    preWeddingCandidate: guest.preWeddingCandidate,
                                    preWeddingConfirmed: preWeddingConfirmed
                                })}
                            onClick={() => {
                                confirmGuest({
                                    id: guest.id,
                                    name: guest.name,
                                    lastName: guest.lastName,
                                    menu: menu.toUpperCase(),
                                    allergies: allergies,
                                    fromBus: fromBus,
                                    returnBus: returnBus,
                                    returnBusTime: toReturnBusScheduleKey(returnBusTime),
                                    confirmed: true,
                                    preWeddingCandidate: preWeddingCandidate,
                                    preWeddingConfirmed: preWeddingConfirmed
                                }, setModalIsOpen, setErrorModalIsOpen)
                            }}>
                            Confirmar asistencia
                        </button>
                        <a href={HOME_PATH} className="linkButton">
                            <button type="button" className="buttonSecondary">Cancelar</button>
                        </a>
                    </div>
                </div>
                <WeddingModal isOpen={modalIsOpen} closeButton={true} setModalIsOpen={setModalIsOpen} title="Asistencia confirmada" confirmationButton={true} confirmationButtonText="Volver a inicio" confirmationHref={HOME_PATH} >
                    Confirmado, muchas gracias! Si tienes que hacer alguna modificación puedes acceder de nuevo a este formulario.
                </WeddingModal>
                <WeddingModal isOpen={errorModalIsOpen} closeButton={true} setModalIsOpen={setErrorModalIsOpen} title="Error" confirmationButton={false}>
                    Ha ocurrido un error, por favor intentelo de nuevo. Si el error persiste contacte con los novios.
                </WeddingModal>
            </div>
        </div>
    )
}


function confirmGuest(guest: Guest, setModalIsOpen: (isOpen: boolean) => void, setErrorModalIsOpen: (isOpen: boolean) => void) {
    updateGuest(guest)
        .then(response => {
            if (response.status === 201) {
                setModalIsOpen(true);
            }
            else {
                setErrorModalIsOpen(true)
            }
        })
        .catch(error => {
            console.error(error);
        })

}

function isValidData(menuUpdated: boolean, fromBusUpdated: boolean, returnBusUpdated: boolean, returnBusTimeUpdated: boolean, preWeddingUpdated: boolean, guest: Guest): boolean {
    const value = menuUpdated && fromBusUpdated && returnBusUpdated && (!guest.preWeddingCandidate || guest.preWeddingCandidate && preWeddingUpdated) &&
        (guest.menu !== null && guest.menu !== MenuType.NONE) &&
        (!guest.returnBus || (guest.returnBus && returnBusTimeUpdated && ReturnBusSchedule.EMPTY !== toReturnBusSchedule(guest.returnBusTime))) &&
        (!guest.preWeddingCandidate || (guest.preWeddingCandidate && (guest.preWeddingConfirmed === false || guest.preWeddingConfirmed === true)))

    return value;
}

function renderPreWedding(guest: Guest, preWedding: {
    preWeddingConfirmation: boolean,
    preWeddingUpdated: boolean,
    setPreWeddingUpdated: (preWeddingUpdated: boolean) => void,
    setPreWeddingConfirmation: (preWeddingConfirmation: boolean) => void
}, selectorStyles: StylesConfig) {
    if (guest.preWeddingCandidate) {
        return (
            <div className="sectionSeparation">
                <b className="secondaryInputStyle bold">PREBODA</b>
                <div className="defaultDescription">
                    Nos gustaria invitarte a la preboda que se celebrará el día 13 de septiembre de 2024 a partir de las 20:00 en <a href="https://goo.gl/maps/sQxCWEZYAH6Dm11p9">el merendero de Antonio Martín.</a>
                </div>
                <label className="inputLabel" htmlFor="selectMenu">¿Asistirás a la preboda?</label>
                <Select className="inputSelect" styles={selectorStyles} options={[
                    { value: true, label: "Sí" },
                    { value: false, label: "No" },
                ]} id="menus" name="menus" placeholder="Selecciona si asistirás" value={preWedding.preWeddingUpdated ? { value: preWedding.preWeddingConfirmation, label: preWedding.preWeddingConfirmation ? "Sí" : "No" } : null} onChange={(option: any) => {
                    preWedding.setPreWeddingUpdated(true)
                    preWedding.setPreWeddingConfirmation(option.value)
                }} />
            </div>
        );
    }
    return null;
}
