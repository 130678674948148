import React from 'react';
import { WeddingListItem } from '../weddingListItem';
import PaymentData from './paymentData';

interface CompleteParticipationProps {
    hidden: boolean,
    item: WeddingListItem,
    isBringByGuest: boolean,
    setIsBringByGuest: (value: boolean) => void,
    isBringByGuestUpdated: boolean,
    setIsBringByGuestUpdated: (value: boolean) => void
}



function CompleteParticipation(props: CompleteParticipationProps) {

    const displayOptions = () => {
        if(!props.item.canBeBroughtToHome){
            return null;
        }
        return <div className="participationMargins">
            <div className="defaultDescription">¿Te gustaría comprarlo tu y traerlo a casa? ¡Te invitamos encantados ❤️!</div>
            <div className="giftBringMarginTop radioContainer">
                <div>
                    <input type="radio" className="secondaryInputStyle radioInput" id="brinByGuest" name="secondBusRadioGroup" checked={props.isBringByGuestUpdated && props.isBringByGuest} onChange={() => {
                        props.setIsBringByGuestUpdated(true);
                        props.setIsBringByGuest(true);
                    }} />
                    <label className="secondaryInputStyle radioLabel" htmlFor="brinByGuest">Sí, me encargo yo.</label>
                </div>
            </div>
            <div className="radioContainer radioSeparation">
                <div>
                    <input type="radio" className="secondaryInputStyle radioInput" id="brinByUs" name="secondBusRadioGroup" checked={props.isBringByGuestUpdated && !props.isBringByGuest} onChange={() => {
                        props.setIsBringByGuestUpdated(true);
                        props.setIsBringByGuest(false);
                    }} />
                    <label className="secondaryInputStyle radioLabel" htmlFor="brinByUs" onChange={() => {
                        props.setIsBringByGuestUpdated(true);
                        props.setIsBringByGuest(true);
                    }}>No, prefiero hacer transferencia.</label>
                </div>
            </div>
        </div>
    }

    return (
        <div hidden={props.hidden}>
            {displayOptions()}
            <PaymentData hidden={(!props.isBringByGuestUpdated && props.item.canBeBroughtToHome) || (props.isBringByGuestUpdated && props.isBringByGuest)} title={props.item.title} hasTotal={props.item.hasTotal} />
        </div>
    );
}

export default CompleteParticipation;
