
import "./../css/content.css";
import { Component, ReactNode } from "react";
import clauDrawImage from "./../pictures/clauDraw.webp"
import churchImage from "./../pictures/church.webp"
import celebrationImage from "./../pictures/celebration.webp"
import { HomeCardTitle } from "./card/homeCardTitle";
import { HomeCardContent } from "./card/homeCardContent";


export class HomeContent extends Component {

    render(): ReactNode {
        return (
            <div id="homeContentContainer">
                <div className="homeCard">
                    <HomeCardTitle picturePath={clauDrawImage} pictureAlt="Foto de Clau y Jaime" className="homeCardLeft" />
                    <HomeCardContent
                        containerClassName="homeCardRight"
                        text={["¡Estamos muy felices de poder celebrar este día tan importante con todos vosotros en Málaga!"]}
                        centeredText={true}
                    />
                </div>
                <div className="homeCard">
                    <HomeCardTitle picturePath={churchImage} pictureAlt="Foto de la iglesia" title="Ceremonia" className="homeCardRight" />
                    <HomeCardContent
                        containerClassName="homeCardLeft"
                        addressLink="https://goo.gl/maps/PCjP4xv8c5E4r2NV6"
                        text={[
                            "La ceremonia religiosa se celebrará el sábado 14 de Septiembre a las 18:30 de la tarde en la Parroquia de Nuestra Señora del Carmen.",
                            "C. Pl. de Toros Vieja, 23, 29002 Málaga",
                            "Los autobuses saldrán de la misma calle de la iglesia al finalizar la ceremonia religiosa."
                        ]}
                        centeredText={false}
                    />
                </div>
                <div className="homeCard">
                    <HomeCardTitle picturePath={celebrationImage} pictureAlt="Foto de la celebracion" title="Celebración" className="homeCardLeft" />
                    <HomeCardContent
                        containerClassName="homeCardRight"
                        addressLink="https://goo.gl/maps/wCDw1rsdYrUwVFwK6"
                        text={[
                            "La celebración comenzará el mismo sábado 14 de Septiembre a las 20:00 de la tarde aproximadamente en el Cortijo La Casita de Madera (Casilla de Maera).",
                            "Ctra. Coín, 27, 29140 Málaga",
                            "Acabaremos la celebración a las 06:00 de la mañana, aunque habrá autobuses durante toda la noche para que podáis volver a Málaga."
                        ]}
                        linkWithArrow={{
                            text: "Ver horario de autobuses",
                            url: "/informacionUtil"
                        }}
                        centeredText={false}
                    />
                </div>
            </div>
        )
    }
}