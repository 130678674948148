import { Link, useLocation, useNavigate  } from "react-router-dom";
import { WindowService } from "../../windowService";
import "../../css/weddingListParticipation.css"
import { useState, useEffect } from "react";
import { WeddingListCard } from "../weddingListCard";
import { DesktopOrientation } from "../desktopOrientation";
import { CONFIRM_PARTICIPATION_PATH, HOME_PATH, WEDDING_LIST_PATH } from "../../urls";
import { WeddingListItem } from "../weddingListItem";
import notFoundPicture from "../../pictures/notFound.svg";
import ErrorComponent from "../../errorComponent";


export default function WeddingListParticipation() {

    const location = useLocation();
    const [participationName, setParticipationName] = useState(location.state?.participationName ? location.state.participationName : "");
    const [item] = useState(location.state?.item);
    const [participationValue, setParticipationValue] = useState("");
    const [isCompleteParticipationSelected, setCompleteParticipationSelected] = useState(false);
    const { pathname } = useLocation();

    console.log(item)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    function isCompletedItem(item: any) {
        return item.hasTotal && item.currentConsolidated + item.currentUnconsolidated >= item.total;
    }

    function renderContent(){
        return (
            <div className="pageMainContainer">
                <div id="participationTitleAndDescription">
                    <div className="defaultTitle">{item.title}</div>
                    {writeDescription(item)}
                </div>
                <WeddingListCard
                    isCompleted={false}
                    listItem={item}
                    hideTitle={true}
                    desktopOrientationIs={DesktopOrientation.VERTICAL}>
                    <div>
                        <div>
                            <div id="participationFieldsContainer">
                                <div id="completeParticipationContainer" hidden={!isNewParticipation(item) || !item.hasTotal}>
                                    <input type="checkbox" id="completeParticipationCheckbox" name="completeParticipationCheckbox" checked={isCompleteParticipationSelected} onChange={() => {
                                        setCompleteParticipationSelected(!isCompleteParticipationSelected);
                                        setParticipationValue(item.total)
                                    }} hidden={!isNewParticipation(item) || !item.hasTotal} />
                                    <label id="completeParticipationLabel" htmlFor="completeParticipationCheckbox" hidden={!isNewParticipation(item) || !item.hasTotal} >Quiero hacer el regalo completo</label>
                                </div>
                                <div id="participationFields">
                                    <div className="inputContainer">
                                        <label className="participationFieldLabel" htmlFor="participationNameInput">¿Quién hace el regalo?</label>
                                        <input type="text" name="participationNameInput" className="participationFieldInput inputBox" placeholder="Nombre/s" value={participationName} onChange={event => setParticipationName(event.target.value)} />
                                    </div>
                                    {displayQuantityInput(isCompleteParticipationSelected, participationValue, setParticipationValue)}
                                </div>
                            </div>
                        </div>
                        <div id="participationButtons">
                            <Link to={CONFIRM_PARTICIPATION_PATH.replace(":itemId", item.id)} className="participationButton" state={{ item: item, participationName: participationName, participationValue: Number(participationValue), isCompleteParticipationSelected: isCompleteParticipationSelected }}>
                                <button
                                    disabled={!fieldValidationSucceded(participationName, Number(participationValue), isCompleteParticipationSelected)}
                                    className="participationButton buttonPrimary">Participar</button>
                            </Link>
                            <Link to={WEDDING_LIST_PATH} className="participationButton">
                                <button className="buttonSecondary" >Cancelar</button>
                            </Link>
                        </div>
                    </div>
                </WeddingListCard>
            </div>
        )
    }

    return !item || isCompletedItem(item) ? <ErrorComponent
    errorPicture={{ src: notFoundPicture, height: 204, width: 300 }}
    errorText='Página o recurso no encontrado'
    returnButton={{ enabled: false }} /> : renderContent()

}


function writeDescription(item: WeddingListItem) {
    return isNewParticipation(item) ? formatDescription(item.firstParticipationText) : formatDescription(item.secondParticipationText);
}

function formatDescription(description: string) {
    const parts = description.split("\n")
    return (
        <div>
            {parts.map((description, index) => <div key={index} className="defaultDescription">{description}</div>)}
        </div>
    )
}

function fieldValidationSucceded(participationName: String, participationValue: number, isCompleteGiftSelected: boolean): boolean {
    if (isCompleteGiftSelected) {
        return participationName.length >= 3
    }
    else {
        return participationName.length >= 3 &&
            participationValue !== null &&
            participationValue > 0;
    }
}

function displayQuantityInput(isCompleteGiftSelected: boolean, participationValue: string, setParticipationValue: (value: string) => void) {
    if ((WindowService.isDesktop() || WindowService.isTablet()) || (WindowService.isMobile() && !isCompleteGiftSelected)) {
        return (
            <div className="inputContainer">
                <label className={isCompleteGiftSelected ? "disabledInputLabel participationFieldLabel" : "participationFieldLabel"} htmlFor="participationQuantityInput">Introduce la cantidad que quieres aportar</label>
                <input type="number" name="participationNameInput" className={isCompleteGiftSelected ? "disabledInput participationFieldInput" : "participationFieldInput inputBox"} placeholder="0.00 €" disabled={isCompleteGiftSelected} value={participationValue} onChange={event => setParticipationValue(event.target.value)} />
            </div>
        );
    }

}

function isNewParticipation(item: WeddingListItem): boolean {
    return item.currentConsolidated === 0 && item.currentUnconsolidated === 0;
}