import { ReturnBusSchedule } from "./returnBusSchedule";

export function toReturnBusSchedule(returnBusTime: string): ReturnBusSchedule {
    if(returnBusTime === undefined || returnBusTime === null || returnBusTime === "") {
        return ReturnBusSchedule.EMPTY;
    }
    
    switch (returnBusTime.toUpperCase()){
        case "FIRST": return ReturnBusSchedule.FIRST;
        case "SECOND": return ReturnBusSchedule.SECOND;
        case "LAST": return ReturnBusSchedule.LAST;
    }
    return ReturnBusSchedule.EMPTY;   
}

export function toReturnBusScheduleKey(returnBusTime: ReturnBusSchedule): string {
    switch (returnBusTime.toUpperCase()){
        case ReturnBusSchedule.FIRST: return "FIRST";
        case ReturnBusSchedule.SECOND: return "SECOND";
        case ReturnBusSchedule.LAST: return "LAST";
    }
    return ReturnBusSchedule.EMPTY;   
}

export function toReturnBusScheduleValue(returnBusTime: ReturnBusSchedule): string {
    switch (returnBusTime.toUpperCase()){
        case ReturnBusSchedule.FIRST: return "02:00 - 02:30";
        case ReturnBusSchedule.SECOND: return "04:00 - 04:30";
        case ReturnBusSchedule.LAST: return "06:00";
    }
    return "";
    
}