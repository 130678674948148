import { Component, ReactNode } from "react";
import { WindowService } from "../windowService";
import "./../css/listCard.css"
import { DesktopOrientation } from "./desktopOrientation";
import { calculatePercentage } from "./utils";
import { WeddingListItem } from "./weddingListItem";
import { Link } from "react-router-dom";
import { WEDDING_LIST_PATH } from "../urls";


interface CardTitleProps {
    listItem: WeddingListItem,
    children?: JSX.Element,
    hideTitle: boolean,
    desktopOrientationIs: DesktopOrientation;
    isCompleted: boolean,
}

interface CardTitleState {
    consolidatedPercentage: number,
    unconsolidatedPercentage: number,
}

export class WeddingListCard extends Component<CardTitleProps, CardTitleState> {

    constructor(props: CardTitleProps) {
        super(props)
        this.state = {
            consolidatedPercentage: 0,
            unconsolidatedPercentage: 0,
        }
    }


    componentDidMount(): void {
        requestAnimationFrame(() => {
            this.setState({
                consolidatedPercentage: calculatePercentage(this.props.listItem.currentConsolidated, this.props.listItem.total),
                unconsolidatedPercentage: calculatePercentage(this.props.listItem.currentConsolidated + this.props.listItem.currentUnconsolidated, this.props.listItem.total)
            })
        })
    }

    private saveScroll(){
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        window.history.pushState({ scrollPosition }, '');
    }


    private hasLink(): boolean {
        return this.props.listItem.link !== undefined &&
            this.props.listItem.link !== null &&
            this.props.listItem.link.toLowerCase() !== "ninguno";
    }

    render(): ReactNode {
        if ((WindowService.isDesktop() || WindowService.isTablet()) && this.props.desktopOrientationIs === DesktopOrientation.VERTICAL) {
            return (
                <div className="verticalListCardItem">
                    <div className="verticalListCardImageWithLink">
                        <img src={this.props.listItem.picture} alt={`${this.props.listItem.title} picture`} className="verticalListCardTitleImage" />
                        <div hidden={!this.hasLink()}>
                            <a className="linkDiv" href={this.props.listItem.link}>
                                <div className="link"></div>
                            </a>
                        </div>
                    </div>
                    <div className="verticalSpaceBetweenImageAndContent">
                        {this.props.listItem.hasTotal ? 
                        <div className="verticalPercentageBarContent">
                            <div className="verticalPercentageBar">
                                <div className="percentageBarConsolidatedValue" style={{ width: `${this.state.consolidatedPercentage}%` }} />
                                <div className="percentageBarUnconsolidatedValue" style={{ width: `${this.state.unconsolidatedPercentage}%` }} />
                            </div>
                            <div className="verticalPercentageValue">{this.state.unconsolidatedPercentage}%</div>
                            
                            {this.props.isCompleted? null : <div className="verticalListItemTotal">Total: {this.props.listItem.currentConsolidated} de {this.props.listItem.total}€</div> }
                        </div> : null }
                        {this.props.children}
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="listCardItem">
                    <div className="horizontalCenteredLayout">
                        <img src={this.props.listItem.picture} alt={`${this.props.listItem.title} picture`} className="listCardTitleImage" />
                        <div hidden={!this.hasLink()} className="linkContent">
                            <a className="linkDiv" href={this.props.listItem.link}>
                                <div className="link"></div>
                            </a>
                        </div>
                    </div>
                    <div className="spaceBetweenImageAndContent">
                        {!this.props.isCompleted ? (<Link className="listItemTitleLink" onClick={() => this.saveScroll()} preventScrollReset={true} to={WEDDING_LIST_PATH + "/" + this.props.listItem.id} state={{ item: this.props.listItem }}>
                            <div className="listItemTitle" hidden={this.props.hideTitle}>{this.props.listItem.title}</div>
                        </Link>) : <div className="listItemTitle" hidden={this.props.hideTitle}>{this.props.listItem.title}</div>}
                        
                        <div className="listItemSubtitle" hidden={!this.props.listItem.title.toLowerCase().includes("miel")}>Japon + Maldivas</div>
                        {this.props.listItem.hasTotal ?
                        <div className={this.props.isCompleted ? 'percentageBarContent completedItemMargin':'percentageBarContent'}>
                            <div className="percentageBar">
                                <div className="percentageBarConsolidatedValue" style={{ width: `${this.state.consolidatedPercentage}%` }} />
                                <div className="percentageBarUnconsolidatedValue" style={{ width: `${this.state.unconsolidatedPercentage}%` }} />
                            </div>
                            <div className="percentageValue" hidden={!this.props.listItem.hasTotal}>{this.state.unconsolidatedPercentage}%</div>
                        </div>: null}

                        {this.props.listItem.hasTotal && !this.props.isCompleted ? 
                        <div className="totalContent">
                            <div className="listItemTotal" >Total: {this.props.listItem.currentConsolidated} de {this.props.listItem.total}€</div>
                        </div> : null }
                        {this.props.children}
                    </div>
                </div>
            );
        }
    }
}