import { Component } from "react";
import "../css/desktopMenu.css"
import { CONFIRM_ASSISTANCE_PATH, HOME_PATH, USEFUL_INFORMATION, WEDDING_LIST_PATH } from "../urls";
import { WindowService } from "../windowService";


export class DesktopMenu extends Component {

    private getItemClasses(path: string){
        let htmlClass = "";
        if(WindowService.isHomePath()) {
            htmlClass = "lightDesktopMenuItem";
            if(WindowService.isCurrentPath(path)){
                htmlClass += " activeDesktopMenuItem";
            }
            else {
                htmlClass += " desktopMenuItem"
            }
        }
        else {
            htmlClass = "darkDesktopMenuItem";
            if(WindowService.isCurrentPath(path)){
                htmlClass += " activeDesktopMenuItemOnLight";
            }
            else {
                htmlClass += " desktopMenuItem"
            }
        }
        return htmlClass;
    }

    render() {
        if (WindowService.isDesktop()) {
            return (
                <div id="desktopMenuItems">
                    <a className={this.getItemClasses(HOME_PATH)} href={HOME_PATH}>Clau y Jaime</a>
                    <a className={this.getItemClasses(CONFIRM_ASSISTANCE_PATH)} href={CONFIRM_ASSISTANCE_PATH}>Confirmar asistencia</a>
                    <a className={this.getItemClasses(WEDDING_LIST_PATH)} href={WEDDING_LIST_PATH}>Lista de bodas</a>
                    <a className={this.getItemClasses(USEFUL_INFORMATION)} href={USEFUL_INFORMATION}>Información util</a>
                </div>
            );
        }
    }
}