import { Component, ReactNode } from "react";
import "./../../css/homeCardTitle.css"

interface CardTitleProps {
    picturePath: string,
    pictureAlt: string,
    title?: string,
    className: string,
}

export class HomeCardTitle extends Component<CardTitleProps> {

    constructor(props: CardTitleProps) {
        super(props)
    }

    render(): ReactNode {
        if (this.props.title) {
            return (
                <div className={this.props.className} >
                    <div className="containerTitleHeight homeCardMargins">
                        <div className="homeCardTitleContent">
                            <span className="homeCardTitleText" >{this.props.title}</span>
                            <img src={this.props.picturePath} alt={this.props.pictureAlt} className="homeCardTitleImage imageBrightnessFilter" />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className={this.props.className} >
                <div className="containerTitleHeight homeCardMargins">
                    <img src={this.props.picturePath} alt={this.props.pictureAlt} className="homeCardTitleImage" />
                </div>
            </div>
        );
    }

}