import { Component, ReactNode } from "react";
import "./../../css/homeCardContent.css"

interface CardContentProps {
    text: Array<string>,
    addressLink?: string,
    containerClassName: string,
    linkWithArrow?: {
        text: string,
        url: string
    },
    centeredText: boolean
}

export class HomeCardContent extends Component<CardContentProps> {

    constructor(props: CardContentProps) {
        super(props)
    }

    private renderExtraText() {
        return this.props.text.slice(2).map((text, index) =>
            <div key={text + "div" + index} className="horizontalLeftLayout textSeparation homeCardSecondParagraph">
                <span key={text + index}>{text}</span>
            </div>);
    }

    private renderLinkWithArrow() {
        if (this.props.linkWithArrow) {
            return (
                <div className="homeContentText linkSeparation" id="busScheduleTextContent">
                    <a className="horizontalLayout removeLinkUnderline" href={this.props.linkWithArrow?.url}>
                        <span id="busScheduleText">{this.props.linkWithArrow?.text}</span>
                        <span id="pointRightArrow" />
                    </a>
                </div>
            );
        }
    }

    render(): ReactNode {
        return (
            <div className={this.props.containerClassName}>
                <div className="homeCardMargins">
                    <div className={`${this.props.centeredText? "alignTextCenter": ""} horizontalLeftLayout homeContentText homeCardFirstParagraph`}>
                        <span>{this.props.text[0]}</span>
                    </div>
                    <div className="textSeparation homeContentText horizontalLeftLayout">
                        <a className="textBold" href={this.props.addressLink}>{this.props.text[1]}</a>
                    </div>
                    {this.renderExtraText()}
                    {this.renderLinkWithArrow()}
                </div>
            </div>
        );
    }

}