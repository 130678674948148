import { MenuType } from "./menuType"

export function toMenuType(menu: string): MenuType {
    if(menu === undefined || menu === null || menu === ""){
        return MenuType.NONE;
    }
    
    switch (menu.toUpperCase()){
        case "GENERAL": return MenuType.GENERAL;
        case "VEGANO": return MenuType.VEGANO;
        case "INFANTIL": return MenuType.INFANTIL;
    }
    return MenuType.NONE;
}