import "./css/index.css"
import "./css/error.css"
import { NavigateFunction, useNavigate } from "react-router";

interface ErrorProps {
    title?: string,
    errorPicture: {
        src: string,
        height: number,
        width: number
    },
    errorText: string,
    returnButton: {
        enabled: boolean,
        text?: string,
        url?: string
    }
}

export default function ErrorComponent(props: ErrorProps) {
    const navigate = useNavigate();

    return (
        <div className="pageMainContainer">
            <div className="defaultMargings">
                <div className={props.title ? "defaultTitle" : ""}>{props.title}</div>
                <div className="horizontalCenteredLayout">
                    <img id="errorPicture"
                        style={{ 
                            height: props.errorPicture.height, 
                            width: props.errorPicture.width 
                        }}
                        src={props.errorPicture.src}
                        alt="Error" />
                </div>
                <div className="alignTextCenter" id="errorTextContainer">
                    <span id="errorText">{props.errorText}</span>
                    {renderReturnButton(navigate, props.returnButton)}
                </div>
            </div>
        </div>
    )
}

function renderReturnButton(navigate: NavigateFunction, returnButton: { enabled: boolean, text?: string, url?: string }) {
    if (returnButton.enabled) {
        return (
            <button
                type="submit"
                className="buttonPrimary"
                id="returnButton"
                onClick={() => navigate(String(returnButton.url), {})}>{returnButton.text}</button>
        );
    }
    return null;
}