import { WeddingListItem } from "./weddingListItem";

export async function fetchWeddingList() {
  try {
    const response = await fetch('https://backend.moliclau.com/weddingList');
    return await response.json();
  } catch (error) {
    console.error('Error fetching wedding list:', error);
  }
}

export async function sendNewParticipation(item: WeddingListItem, bringToHome: boolean, participation: number, name: string) {
  try {
    const response = await fetch(`https://backend.moliclau.com/weddingListItem/${item.id}/participations`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "participantName": name,
        "validated": false,
        "participation": participation,
        "thanksGiven": false,
        "bringToHome": bringToHome
      }),
    });
    return await response.json();
  } catch (error) {
    console.error('Error adding participation:', error);
  }
}
