import Modal from 'react-modal';
import React, { ReactNode } from "react";
import { ModalHeader } from './modalHeader';
import "./../css/modal.css"

interface Props {
    isOpen: boolean,
    setModalIsOpen: (isOpen: boolean) => void;
    title: string,
    confirmationButton: boolean,
    closeButton: boolean,
    onConfirm?: () => void;
    confirmationHref?: string;
    confirmationButtonText?: string,
    children?: ReactNode;
    isFullScreen?: boolean
}

export class WeddingModal extends React.Component<Props, {}> {

    render() {
        return (
            <div className='modalMainContainer' hidden={!this.props.isOpen}>
                <Modal isOpen={this.props.isOpen} overlayClassName={this.props.isFullScreen? "fullScreenModalOverlay":"modalOverlay"} style={
                    {
                        content: {
                            padding: 0,
                            border: "none",
                            outline: "none",
                        },
                        overlay: {
                            borderRadius: 8
                        }
                    }} ariaHideApp={false} className="modal">
                    <ModalHeader title={this.props.title} closeButton={this.props.closeButton} onClose={() => this.props.setModalIsOpen(false)} isFullScreen={this.props.isFullScreen}></ModalHeader>
                    <div className="modalContent">
                        {this.props.children}
                    </div>
                    <div className='modalConfirmationContainer'>
                        <a href={this.props.confirmationHref}>
                            <button className='buttonPrimary' hidden={!this.props.confirmationButton} onClick={this.props.onConfirm}>{this.props.confirmationButtonText}</button>
                        </a>
                    </div>
                </Modal>
            </div>
        )
    }



}
