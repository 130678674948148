import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../css/weddingListParticipation.css"
import { calculatePercentage } from "../utils";
import { HOME_PATH, WEDDING_LIST_ITEM_PATH } from "../../urls";
import { WeddingListItem } from "../weddingListItem";
import { useState, useEffect } from "react";
import { WeddingModal } from "../../modal/weddingModal";
import CompleteParticipation from "./completeParticipation";
import PaymentData from "./paymentData";
import { sendNewParticipation } from "../weddingListClient";

export default function ConfirmationContainer() {
    const location = useLocation();
    const navigate = useNavigate();

    const item: WeddingListItem = location.state.item;
    const name: string = location.state.participationName;
    const value: number = location.state.participationValue;
    const isCompleteParticipationSelected: boolean = location.state.isCompleteParticipationSelected;

    const { pathname } = useLocation();
    const [isBringByGuest, setIsBringByGuest] = useState(false);
    const [isBringByGuestUpdated, setIsBringByGuestUpdated] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    function addParticipation() {
        sendNewParticipation(item, isBringByGuest, value, name)
        setModalIsOpen(true);
    }

    function displayPercentageBar() {
        if (item.hasTotal) {
            return (
                <div className="percentageBarWrapper">
                    <div className="percentageBarContent">
                        <div className="percentageBar">
                            <div className="percentageBarConsolidatedValue" style={{ width: `${calculatePercentage(item.currentConsolidated, item.total)}%` }} />
                            <div className="percentageBarUnconsolidatedValue" style={{ width: `${calculatePercentage(item.currentConsolidated + item.currentUnconsolidated + value, item.total)}%` }} />
                        </div>
                        <div className="percentageValue">{calculatePercentage(item.currentConsolidated + item.currentUnconsolidated + value, item.total)}%</div>
                    </div>
                    <div className="totalContent">
                        <div className="listItemTotal">Total: {item.total}€</div>
                    </div>
                    <div id="cancelConfirmationContent">
                        <Link to={WEDDING_LIST_ITEM_PATH.replace(":itemId", item.id.toString())} className="participationButton" state={{ item: item, participationName: name }}>
                            <span id="cancelConfirmation">Modificar cantidad</span>
                        </Link>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="percentageBarWrapper">
                    <div className="percentageBarContent">
                        <div className="percentageBar">
                            <div className="percentageBarUnconsolidatedValue" style={{ width: `${calculatePercentage(item.currentConsolidated + item.currentUnconsolidated + value, item.total)}%` }} />
                        </div>
                    </div>
                    <div className="totalContent">
                        <div className="listItemTotal">Total: - €</div>
                    </div>
                    <div id="cancelConfirmationContent">
                        <Link to={WEDDING_LIST_ITEM_PATH.replace(":itemId", item.id.toString())} className="participationButton" state={{ item: item, participationName: name }}>
                            <span id="cancelConfirmation">Modificar cantidad</span>
                        </Link>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="pageMainContainer">
            <div id="weddingListConfirmation">
                <div id="participationTitleAndDescription">
                    <div className="defaultTitle">{item.title}</div>
                    <div className="defaultDescription">
                        <span>
                            ¡Muchas gracias {name}! Has decidido aportar un total de <strong>{value}€</strong> para el siguiente artículo: <strong>{item.title}</strong>.
                        </span>
                    </div>
                </div>
                {displayPercentageBar()}
                <CompleteParticipation
                    hidden={!isCompleteParticipationSelected}
                    item={item}
                    isBringByGuest={isBringByGuest}
                    isBringByGuestUpdated={isBringByGuestUpdated}
                    setIsBringByGuest={setIsBringByGuest}
                    setIsBringByGuestUpdated={setIsBringByGuestUpdated}
                />

                <PaymentData hidden={isCompleteParticipationSelected} title={item.title} hasTotal={item.hasTotal} />


                <div className="participationMargins confirmationButtons">
                    <button className="buttonPrimary" onClick={() => addParticipation()}>Aceptar</button>
                    <button className="buttonSecondary" onClick={() => navigate("/listaDeBodas")}>Cancelar</button>
                </div>
            </div>
            <WeddingModal isOpen={modalIsOpen} closeButton={false} setModalIsOpen={setModalIsOpen} title="¡Muchas gracias!" confirmationButton={true} confirmationButtonText="Volver a inicio" confirmationHref={HOME_PATH} >
                {isCompleteParticipationSelected && isBringByGuest ? <div><div>Tu regalo ha quedado registrado,</div><div>habla con nosotros para ver qué día quedamos 🤩</div></div> : "Tu regalo ha quedado registrado, en cuánto nos llegue la transferencia lo verás reflejado en la web 🤩"}
            </WeddingModal>
        </div>
    )

}

