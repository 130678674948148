import { Component, ReactNode } from "react";
import { CONFIRM_ASSISTANCE_PATH } from "../urls";
import "./../css/cover.css";

export class HomeCover extends Component {

    render(): ReactNode {
        return (
            <div id="coverContainer">
                <div id="coverPicture">
                    <div id="coverPictureTransparency">
                        <div id="titleContainer">
                            <div id="title">
                                <div id="jaimeClau" className="centeredTextOverImageContainer">Clau y Jaime</div>
                                <div id="date" className="centeredTextOverImageContainer">14 Septiembre 2024</div>
                                <div className="centeredTextOverImageContainer">
                                    <a href={CONFIRM_ASSISTANCE_PATH} className="linkButton">
                                        <button id="assistBtn">Confirmar asistencia</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="infoPlusVectorContainer">
                            <div className="centeredTextOverImageContainer">
                                <span id="moreInfo">mas info</span>
                            </div>
                            <div id="vectorContainer">
                                <div id="downVector" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}