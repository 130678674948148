import React from 'react';
import ReactDOM from 'react-dom/client';
import "./css/index.css";
import "./css/fonts.css"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './home/home';
import { MobileMenu } from './menus/mobileMenu';
import { Contact } from './contact';
import { Header } from './header';
import { UsefulInformation } from './information/usefulInformation';
import { CONFIRM_ASSISTANCE_ASSIST_PATH, CONFIRM_ASSISTANCE_PATH, HOME_PATH, CONFIRM_PARTICIPATION_PATH, WEDDING_LIST_ITEM_PATH, WEDDING_LIST_PATH, USER_NOT_FOUND, USEFUL_INFORMATION } from './urls';
import ErrorComponent from './errorComponent';
import ConfirmationPeopleSeeker from './confirmation/confirmationPeopleSeeker';
import ConfirmationForm from './confirmation/confirmationForm';
import notFoundPicture from "./pictures/notFound.svg";
import warningPicture from "./pictures/warning.svg";
import ConfirmationContainer from './weddingList/participation/confirmationContainer';
import { WeddingList } from './weddingList/weddingList';
import WeddingListParticipation from './weddingList/participation/weddingListParticipation';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: HOME_PATH,
    element: <Home />,
  },
  {
    path: WEDDING_LIST_PATH,
    element: <WeddingList />,
  },
  {
    path: WEDDING_LIST_ITEM_PATH,
    element: <WeddingListParticipation />,
    errorElement: <ErrorComponent
      errorPicture={{ src: notFoundPicture, height: 204, width: 300 }}
      errorText='Ha ocurrido un problema al cargar el elemento.'
      returnButton={{ enabled: false }} />
  },
  {
    path: USEFUL_INFORMATION,
    element: <UsefulInformation />
  },
  {
    path: CONFIRM_PARTICIPATION_PATH,
    element: <ConfirmationContainer />,
    errorElement: <ErrorComponent
      errorPicture={{ src: notFoundPicture, height: 204, width: 300 }}
      errorText='Ha ocurrido un problema al cargar el elemento.'
      returnButton={{ enabled: false }} />
  },
  {
    path: CONFIRM_ASSISTANCE_PATH,
    element: <ConfirmationPeopleSeeker />,
  },
  {
    path: CONFIRM_ASSISTANCE_ASSIST_PATH,
    element: <ConfirmationForm />,
  },
  {
    path: USER_NOT_FOUND,
    element: <ErrorComponent title='Confirmar asistencia'
      errorPicture={{ src: warningPicture, height: 100, width: 300 }}
      errorText='Ups, algo ha ido mal, vuelve a intentarlo o ponte en contacto con nosotros'
      returnButton={{ enabled: true, text: "Volver a intentarlo", url: CONFIRM_ASSISTANCE_PATH }} />,
  },
  {
    path: "*",
    element: <ErrorComponent
      errorPicture={{ src: notFoundPicture, height: 204, width: 300 }}
      errorText='Página o recurso no encontrado'
      returnButton={{ enabled: false }} />
  }
]);

ReactDOM
  .createRoot(document.getElementById('root') as HTMLElement)
  .render(
    <div id='appContent'>
      <Header />
      <MobileMenu />
      <RouterProvider router={router} />
      <Contact />
    </div>
  );
