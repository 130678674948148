import React from "react";
import { DesktopMenu } from "./menus/desktopMenu";
import "./css/header.css";
import { WindowService } from "./windowService";
import { HOME_PATH } from "./urls";

export class Header extends React.Component {

    public shouldDisplayComponent(): boolean {
        return WindowService.isDesktop()
    }

    private renderMobileView() {
        return (
            <div id="desktopMenuContainerNoZIndex">
                <div id="desktopMenuContainer">
                    <a id="hashtag" href={HOME_PATH}>#MoliClau</a>
                </div>
            </div>
        )
    }

    private renderDesktopView() {
        return (
            <div id="desktopMenuContainerNoZIndex">
                <div id="desktopMenuContainer">
                    <a id={WindowService.isHomePath()? "hashtag" : "darkHashtag"} href={HOME_PATH}>#MoliClau</a>
                    <DesktopMenu />
                </div>
            </div>
        )
    }


    render(): React.ReactNode {
        if (WindowService.isDesktop()) {
            return this.renderDesktopView();
        }
        else if ((WindowService.isMobile() || WindowService.isTablet())  && WindowService.isHomePath()) {
            return this.renderMobileView();
        }
    }
}