import React, { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CONFIRM_ASSISTANCE_ASSIST_PATH, USER_NOT_FOUND } from "../urls";
import "../css/confirmation.css"
import { getGuestByName } from "../clients/guestClient";

export default function ConfirmationPeopleSeeker() {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");


    return (
        <div className="pageMainContainer" id="confirmationMainDiv">
            <div className="defaultMargings">
                <div className="defaultTitle">
                    Confirmar asistencia
                </div>
                <div className="defaultDescription">
                    ¡Cada vez queda menos para el gran día!
                </div>
                <div className="defaultDescription">
                    Escribe tu nombre y apellidos y pulsa “Buscar”
                </div>
                <div>
                    <div className="confirmationInputGroup">
                        <label className="inputLabel" htmlFor="assistantName">Nombre</label>
                        <input className="input inputBox" type="text" id="assistantName" name="assistantName" value={name} onChange={event => setName(event.target.value)} />
                    </div>
                    <div className="confirmationInputGroup">
                        <label className="inputLabel" htmlFor="assistantLastName">Apellidos</label>
                        <input className="input inputBox" type="text" id="assistantLastName" name="assistantLastName" value={lastName} onChange={event => setLastName(event.target.value)} />
                    </div>
                    <div className="confirmationInputGroup">
                        <button type="submit" className="buttonPrimary" disabled={!name || !lastName} onClick={() => openAssistanceConfirmationForm(navigate, name, lastName)}>Buscar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function openAssistanceConfirmationForm(navigate: NavigateFunction, name: string, lastName: string): void {
    getGuestByName(name, lastName)
        .then(response => {
            if (response.status === 200) {
                response.json().then(guest => {
                    navigate(CONFIRM_ASSISTANCE_ASSIST_PATH, { state: { guest: guest } })
                });
            }
            else {
                navigate(USER_NOT_FOUND)
            }
        })
        .catch(error => {
            console.error(error)
        })
}