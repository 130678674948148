import "./../css/home.css";
import { useEffect } from "react";
import { HomeCover } from "./homeCover";
import { HomeContent } from "./homeContent";
import { CONFIRM_ASSISTANCE_PATH } from "../urls";
import { useLocation } from "react-router";

export default function Home() {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div id="home">
            <HomeCover />
            <HomeContent />
            <div id="confirmationContainer">
                <div id="confirmationTextAndImage">
                    <div id="confirmationImageContainer">
                        <div id="confirmationImage">
                        </div>
                    </div>
                    <div className="verticalCenteredLayout" id="confirmationQuestionContainer" >
                        <span id="confirmationQuestion">¿Nos has confirmado que vienes?</span>
                        <div id="confirmAssistButtonContainer">
                            <a href={CONFIRM_ASSISTANCE_PATH} className="linkButton">
                                <button className="buttonPrimary" id="confirmAssistButton">Confirmar Asistencia</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}