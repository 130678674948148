import React from 'react';

interface PaymentDataProps {
    hidden: boolean,
    title: string,
    hasTotal: boolean
}

const PaymentData: React.FC<PaymentDataProps> = (props) => {


    function displayBarInformation() {
        if (!props.hasTotal) {
            return null;
        }
        return (
            <div className="defaultDescription participationMargins">
                Ahora mismo verás la línea de progreso de color azulito.
                Cuando nos llegue la transferencia y lo confirmemos podrás ver que la barra gris pasa a ser de color rosa.
            </div>
        );
    }

    return (
        <div hidden={props.hidden}>
            <div className="defaultDescription participationMargins">Para ello, puedes hacernos una transferencia a nuestra cuenta bancaria poniendo en el asunto tu nombre y “{props.title}”.</div>
            <div id="bankData">
                <div className="verticalCenteredLayout">
                    <div id="accountNumber"><span><strong>ES30 0182 4062 9102 0164 1712</strong></span></div>
                </div>
            </div>
            <div className="defaultDescription participationMargins">
                No te olvides de darle a “Aceptar” para que quede registrada tu participación en nuestro sistema.
            </div>
            {displayBarInformation()}
        </div>
    );
};

export default PaymentData;
