import { Component } from "react";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import "../css/mobileMenu.css"
import { CONFIRM_ASSISTANCE_PATH, HOME_PATH, USEFUL_INFORMATION, WEDDING_LIST_PATH } from "../urls";
import { WindowService } from "../windowService";


interface MenuState {
    displayMenu: boolean
}

export class MobileMenu extends Component<{}, MenuState> {

    constructor(props: {}) {
        super(props)
        this.state = {
            displayMenu: (WindowService.isMobile() || WindowService.isTablet()) ? false : true
        }
    }

    private updateDisplayMenuVisibility() {
        this.setState({ displayMenu: !this.state.displayMenu })
    }

    private renderHamburgerButton() {
        if (!this.state.displayMenu) {
            return (<div id="hamburgerContainer">
                <div id="hamburger" onClick={this.updateDisplayMenuVisibility.bind(this)} />
            </div>);
        }
    }

    private renderMobileMenu() {
            return (
                <div className={this.state.displayMenu ? "display" : "hide"}>
                    {this.state.displayMenu ? <RemoveScrollBar /> : null}
                    <div className="closeHamburger" onClick={this.updateDisplayMenuVisibility.bind(this)} />                    
                    <div id="mobileMenuItems">
                        <a className="mobileMenuItem" href={HOME_PATH}>Clau y Jaime</a>
                        <a className="mobileMenuItem" href={CONFIRM_ASSISTANCE_PATH}>Confirmar asistencia</a>
                        <a className="mobileMenuItem" href={WEDDING_LIST_PATH}>Lista de bodas</a>
                        <a className="mobileMenuItem" href={USEFUL_INFORMATION}>Información util</a>
                    </div>
                </div>
            )
    }

    render() {
        if (WindowService.isMobile() || WindowService.isTablet()) {
            return (
                <div>
                    {this.renderHamburgerButton()}
                    <div id={this.state.displayMenu ? "mobileMenu" : "mobileMenuClosed"}>
                        {this.renderMobileMenu()}
                    </div>
                </div>
            );
        }
    }
}