import { HOME_PATH, MAIN_PATH } from "./urls";

export class WindowService {
    private static readonly MAX_TABLET_WIDTH = 1280;
    private static readonly MAX_MOBILE_WIDTH = 426;

    public static getLastPathParam() {
        return window.location.pathname;
    }

    public static isMobile() {
        return window.innerWidth <= this.MAX_MOBILE_WIDTH
    }

    public static isTablet() {
        return window.innerWidth > this.MAX_MOBILE_WIDTH && window.innerWidth <= this.MAX_TABLET_WIDTH
    }

    public static isDesktop() {
        return window.innerWidth > this.MAX_TABLET_WIDTH

    }

    public static isHomePath() {
        return this.isReceivedPathHomePath(window.location.pathname)
    }

    public static isReceivedPathHomePath(path: string) {
        return path.includes(HOME_PATH) || path === MAIN_PATH
    }

    public static isCurrentPath(path: string) {
        return window.location.pathname.startsWith(path)
    }

}

