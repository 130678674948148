import React from "react";
import Collapsible from 'react-collapsible';
import "./../css/usefulInformation.css";
import jaimePedidaImage from "./../pictures/jaimePedida.webp"
import patoPedidaImage from "./../pictures/patoPedida.webp"


export class UsefulInformation extends React.Component {
    render(): React.ReactNode {
        return (
            <div className="pageMainContainer">
                <div className="defaultMargings">
                    <div className="defaultTitle">
                        Información util
                    </div>
                    <div id="description">
                        <div className="defaultDescription text">
                            En este apartado encontrarás toda la información que te podría hacer falta para la boda y para venir a Málaga.
                        </div>
                        <div className="defaultDescription text">
                            ¡Cualquier duda que tengas no dudes en escribirnos!
                        </div>
                    </div>
                    <Collapsible trigger="Viaje a Málaga" triggerClassName="closedCollapsibleTrigger" triggerOpenedClassName="openedCollapsibleTrigger">
                        <div className="marginBottom text">Todos los que hayáis venido a Málaga sabréis que usar el coche en el centro es un rollo; por eso para venir, nosotros te recomendamos venir en tren, más concretamente en <strong>iryo</strong> que suele ser bastante más barato que AVE Renfe y tarda lo mismo.</div>
                        <div className="bigMarginBottom text">De todas formas, aquí os dejamos los enlaces para que podais comprar los billetes con cualquiera de los dos proveedores:</div>
                        <a className="bigMarginBottom linkWithArrow" href="https://iryo.eu/es/home">Tren iryo</a>
                        <a className="bigMarginBottom linkWithArrow" href="https://www.renfe.com/es/es/viajar/prepara-tu-viaje/billetes-ave-y-largadistancia/todos-los-billetes">AVE Renfe</a>
                    </Collapsible>
                    <div className="separator"></div>
                    <Collapsible trigger="Dónde dormir en Málaga" triggerClassName="closedCollapsibleTrigger" triggerOpenedClassName="openedCollapsibleTrigger">
                        <div className="marginBottom text">En Málaga hay miles de hoteles, hostales, residencias y apartamentos para quedarse.</div>
                        <div className="marginBottom text">Os recomendamos las siguientes zonas:</div>
                        <ul>
                            <li>Cerca de la iglesia (Barrio El Perchel): a ser posible mas cerca del río que de la estación de tren.</li>
                            <li>Soho: cuanto mas cerca del rio más barato. Esta zona está llena de apartamentos y se encuentra más cerca del centro de Málaga.</li>
                            <li>Centro: Suele ser más caro, pero con tiempo es posible encontrar buenas ofertas.</li>
                        </ul>
                        <div className="marginBottom text">Nos hemos puesto en contacto con varios hoteles, pero de momento, no hemos podido conseguir un buen precio. En cualquier caso, nos han comentado que difícilmente nos van a ofrecer mejores precios que los que se pueden encontrar en booking</div>

                        <div className="bigMarginBottom text">Aún así hemos buscado varios hoteles que no están mal de precio y están muy cerquita de la iglesia: </div>
                        <a className="bigMarginBottom linkWithArrow" href="https://www.nh-hoteles.es/hotel/nh-malaga">Hotel NH Málaga</a>
                        <a className="bigMarginBottom linkWithArrow" href="https://www.hotelguadalmedina.es/">Hotel Guadalmedina</a>
                        <a className="bigMarginBottom linkWithArrow" href="https://www.ilunionmalaga.com/">Hotel Ilunion</a>

                        <div className="bigMarginBottom text">No dejeis de contemplar la opción de coger un apartamento sobre todo si vais en grupo.</div>
                        <a className="bigMarginBottom linkWithArrow" href="https://www.airbnb.es/">Airbnb</a>
                        <a className="bigMarginBottom linkWithArrow" href="https://www.booking.com/index.es.html">Booking</a>



                    </Collapsible>
                    <div className="separator"></div>
                    <Collapsible trigger="Autobuses" triggerClassName="closedCollapsibleTrigger" triggerOpenedClassName="openedCollapsibleTrigger">
                        <div className="marginBottom text">Para llegar a la iglesia os recomendamos coger un taxi (no será caro si estáis alojados donde os hemos recomendado).</div>
                        <div className="marginBottom text">Después de la ceremonia, habrá buses esperando en la <strong>misma calle de la iglesia</strong> para llevaros a nuestra finca en Churriana.</div>
                        <div className="marginBottom text">Para volver a Málaga después de la celebración, habrá autobuses disponibles a diferentes horas que pararán en dos puntos de Málaga centro, siempre cerca de paradas de taxi por si no os viniera bien ninguna de las dos.</div>
                        <div className="subTitle">Horario autobuses vuelta</div>
                        <div className="marginBottom busContainer">
                            <div className="text"><strong>02:15</strong> Desde Churriana a Málaga centro</div>
                            <div className="text"> 1 autobús</div>
                            <div className="text"><strong>Paradas: </strong> Corte inglés y Plaza de la Marina</div>
                        </div>
                        <div className="marginBottom busContainer">
                            <div className="text"><strong>04:15</strong> Desde Churriana a Málaga centro</div>
                            <div className="text"> 2 autobuses</div>
                            <div className="text"><strong>Paradas: </strong> Corte inglés y Plaza de la Marina</div>
                        </div>
                        <div className="marginBottom busContainer">
                            <div className="text"><strong>06:00</strong> Desde Churriana a Málaga y Pedregalejo</div>
                            <div className="text"> 2 autobuses</div>
                            <div className="text"><strong>Paradas bus 1: </strong> Corte inglés y Plaza de la Marina</div>
                            <div className="text"><strong>Paradas bus 2: </strong> Corte inglés y Pedregalejo</div>
                            <div className="text">(Avenida Juan Sebastian Elcano con la calle Juan Valera)</div>
                        </div>
                    </Collapsible>
                    <div className="separator"></div>
                    <Collapsible trigger="Peluquerías" triggerClassName="closedCollapsibleTrigger" triggerOpenedClassName="openedCollapsibleTrigger">
                        <div className="bigMarginBottom text">Para nuestras invitadas más presumidas, os dejamos varias peluquerías cercanas a la iglesia donde sabemos que os dejarán guapísimas:</div>

                        <a className="marginBottom linkWithArrow" href="https://maps.app.goo.gl/Z17Yjj1KcmrLvZaB8">Peluquería Gonzalo Aneas</a>
                        <div className="smallMarginBottom text">El horario es de 09:00- 14:00 los sábados.</div>
                        <div className="smallMarginBottom text">Llamar al 952004461 / 660397967 y preguntar por Gonzalo para conseguir horario de tarde</div>
                        <div className="smallMarginBottom text"><strong>Precio peinado sencillo:</strong> a partir de 22€</div>
                        <div className="bigMarginBottom text">*Llamar para asegurar precio según peinado</div>

                        <a className="marginBottom linkWithArrow" href="https://maps.app.goo.gl/Sryg9k77LQk9hBzf7">EXPERIENCE Cecilia Velasco</a>
                        <div className="smallMarginBottom text">El horario es de 09:00 - 14:00 los sábados.</div>
                        <div className="smallMarginBottom text">Llamar al 688871049 y preguntar por Eder Ferreira para conseguir horario de tarde.</div>
                        <div className="smallMarginBottom text"><strong>Precio peinados:</strong> en torno a 45€ </div>
                        <div className="bigMarginBottom text">*Llamar para asegurar precio según peinado</div>

                        <a className="marginBottom linkWithArrow" href="https://maps.app.goo.gl/7kXXsGvekRBgQL8f9">Daniel Veiga Vitale, Hair & Beauty</a>
                        <div className="smallMarginBottom text">El horario es de 10:00 - 19:00 los sábados.</div>
                        <div className="smallMarginBottom text"><strong>Precio peinados:</strong> entre 30 - 70€</div>
                        <div className="bigMarginBottom text">*Llamar para asegurar precio según peinado</div>

                        <a className="marginBottom linkWithArrow" href="https://maps.app.goo.gl/VyWZynaBkpqyEkVHA">Agustín e Hijos Peluqueros</a>
                        <div className="smallMarginBottom text">El horario es de 09:00 - 15:00 los sábados.</div>
                        <div className="smallMarginBottom text"><strong>Precio peinado sencillo:</strong> a partir de 25€</div>
                        <div className="bigMarginBottom text">*Llamar para asegurar precio según peinado</div>

                        <a className="marginBottom linkWithArrow" href="https://maps.app.goo.gl/RxePnrPchKnoyqVR9">C.O.C.C.O Beauty</a>
                        <div className="smallMarginBottom text">El horario es de 09:00 - 16:00 los sábados.</div>
                        <div className="smallMarginBottom text"><strong>Precio peinado sencillo:</strong> a partir de 22€</div>
                        <div className="bigMarginBottom text">*Llamar para asegurar precio según peinado</div>

                    </Collapsible>
                    <div className="separator"></div>
                    <Collapsible trigger="¿Por qué el pato en el logo?" triggerClassName="closedCollapsibleTrigger" triggerOpenedClassName="openedCollapsibleTrigger">
                        <div className="textWithImageContainer" id="jaimeContainer">
                            <div className="bigMarginBottom text" id="jaimeText1">Creemos que esta es la pregunta más repetida al ver la web 😅. La historia del pato viene de cuando Jaime le pidió matrimonio a Clau.</div>
                            <div className="bigMarginBottom text" id="jaimeText2">Estábamos en Sevilla, concretamente, en el Alcázar, y Jaime llevaba con el anillo encima toda la mañana. Después de darle muchas vueltas, Jaime encontró el sitio perfecto para pedirlo. </div>
                            <div className="bigMarginBottom text" id="jaimeText3">Os enseñamos la foto que Clau hizo justo antes de que pasara (con el famoso patito de fondo paseando y el anillo en el bolsillo de Jaime 🤣):</div>
                            <div className="image">
                                <img id="jaimePedida" className="bigMarginBottom" src={jaimePedidaImage} alt="foto de Jaime posando en el alcazar con el anillo en el bolsillo delantero del abrigo"></img>
                            </div>
                        </div>
                        <div className="textWithImageContainer" id="patoContainer">
                            <div className="bigMarginBottom text" id="patoText1">Después de hacerle esa foto, Clau se fue directa a hacerle fotos al patito y ahí fue cuando Jaime aprovechó para arrodillarse mientras Clau estaba de espaldas 🥰</div>
                            <div className="bigMarginBottom text" id="patoText2">Os dejamos la foto del pato.</div>
                            <div className="bigMarginBottom text" id="patoText3">Para Clau, el momento de las fotos al pato duró tan solo un segundo pero, por otro lado, a Jaime, se le hizo eterno y de ahí que nos haga tanta gracia el patito 😅</div>
                            <div className="image" id="patoImageContainer">
                                <img id="patoPedida" className="bigMarginBottom" src={patoPedidaImage} alt="Foto del pato que Clau estaba fotografiando mientras Jaime le pedía matrimonio"></img>
                            </div>
                        </div>
                    </Collapsible>
                    <div className="separator"></div>
                    <Collapsible trigger="Otros" triggerClassName="closedCollapsibleTrigger" triggerOpenedClassName="openedCollapsibleTrigger">
                        <div className="bigMarginBottom text">Para cualquier otra duda, ponte en contacto con nosotros 🤗</div>
                    </Collapsible>
                    <div className="separator endMarging"></div>
                </div>
            </div>
        )
    }
}