import { Component, ReactNode } from "react";
import { Link, ScrollRestoration } from "react-router-dom";
import { WEDDING_LIST_PATH } from "../urls";
import { WeddingListCard } from "./weddingListCard";
import "../css/listCard.css"
import { DesktopOrientation } from "./desktopOrientation";
import { fetchWeddingList } from "./weddingListClient";
import { WeddingListItem } from "./weddingListItem";
import { WeddingModal } from "../modal/weddingModal";


interface WeddingListProps { }

interface WeddingListState {
    items: any[];
    helpModalIsOpen: boolean;
}

export class WeddingList extends Component<WeddingListProps, WeddingListState> {


    componentDidMount(): void {
        this.setState({ helpModalIsOpen: false });
        fetchWeddingList()
            .then((items) => {
                const firstItems = items.filter((item: WeddingListItem) => !item.hasTotal).sort((a: WeddingListItem, b: WeddingListItem) => a.title.localeCompare(b.title));
                const nextItems = items.filter((item: WeddingListItem) => item.hasTotal && !this.isCompletedItem(item)).sort((a: WeddingListItem, b: WeddingListItem) => a.title.localeCompare(b.title));
                const lastItems = items.filter((item: WeddingListItem) => item.hasTotal && this.isCompletedItem(item)).sort((a: WeddingListItem, b: WeddingListItem) => a.title.localeCompare(b.title));
                this.setState({ items: firstItems.concat(nextItems).concat(lastItems)})
            });

        this.restoreScroll();
    }


    handlePopState = (event: PopStateEvent) => {
        if (event.state && event.state.scrollPosition) {
            setTimeout(() => {
                window.scrollTo(0, event.state.scrollPosition);
            }, 300);
            window.history.replaceState({ scrollPosition: 0 }, '');
        }
    };

    restoreScroll() {
        window.addEventListener('popstate', this.handlePopState);
    }


    private isCompletedItem(item: any){
        return item.hasTotal && item.currentConsolidated + item.currentUnconsolidated >= item.total;
    }

    private saveScroll(){
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        window.history.pushState({ scrollPosition }, '');
    }

    private renderItem(item: any): ReactNode {
        return (
            <WeddingListCard key={item.id} listItem={item} hideTitle={false} isCompleted={this.isCompletedItem(item)} desktopOrientationIs={DesktopOrientation.HORIZONTAL}>
                <div className="participateButton">
                    {this.isCompletedItem(item) ? this.renderDisabledButton(): this.renderEnabledButton(item) }
                </div>
            </WeddingListCard>
        );
    }

    private renderEnabledButton(item: any) {
        return (
        <Link onClick={()=> this.saveScroll()} preventScrollReset={true} to={WEDDING_LIST_PATH + "/" + item.id} state={{ item: item }}>
            <button className="buttonSecondary">Participar</button>
        </Link>
        );
    }

    private renderDisabledButton() {
        return (
            <button disabled={true} className="buttonSecondary">Participación completa</button>
        );
    }

    private renderItems(): ReactNode {
        if (!this.state || !this.state.items) {
            return null;
        }

        return this.state.items.map((item) => this.renderItem(item));
    }

    private openHelpModal(isOpen: boolean): void {
        this.setState({ helpModalIsOpen: isOpen });
    }

    render(): ReactNode {
        return (
            <div className="pageMainContainer">
                <div className="defaultMargings">
                    <div className="defaultTitle">Lista de bodas</div>
                    <div className="defaultDescription">El mejor regalo que nos podéis hacer es venir a Málaga a celebrar este día tan importante con nosotros.</div>
                    <div className="defaultDescription">De todas formas, si has llegado hasta aquí, es porque quieres tener un detalle con nosotros.</div>
                    <div className="defaultDescription">Como muchos ya sabréis, esta boda significa que por fin nos vamos a vivir juntos y por eso necesitamos construir nuestro nuevo hogar de cero.</div>
                    <div className="defaultDescription">Os dejamos aquí nuestra lista 🙈</div>
                </div>
                <div className="containerSeparation" />
                <div id="helpContainer" className="defaultMargings">
                    <div className="pictureWithText" onClick={() => this.openHelpModal(true)}>
                        <div id="help" />
                        <div id="helpText">Ayuda</div>
                    </div>
                </div>
                <div id="weddingListCardItems">
                    {this.renderItems()}
                </div>
                <WeddingModal
                    isOpen={this.state?.helpModalIsOpen}
                    setModalIsOpen={(isOpen) => this.openHelpModal(isOpen)}
                    closeButton={true}
                    title="Ayuda"
                    confirmationButton={false}
                    isFullScreen={true}
                >
                    <div className="helpModalText">
                        <div>
                            En esta lista podrás encontrar todas las cosas que nos encantaría tener para nuestra nueva casa.
                        </div>
                        <div>
                            Con esta lista de bodas, podréis participar en los regalos que más os gusten de las siguientes maneras:
                        </div>
                        <ul>
                            <li>
                                <strong>Participación parcial:&#160;</strong>
                                Esta participación se puede realizar siempre, haya o no participado alguien en el regalo anteriormente.
                                Consiste en aportar la cantidad elegida en el regalo que más ilusión os haga.
                                Al finalizar el proceso aparecerá el número de cuenta al que realizar la transferencia.
                                Nosotros guardaremos el dinero para gastarlo en ese regalo concreto.
                                Vuestra participación aparecerá en azulito hasta que nosotros manualmente confirmemos que la transferencia ha llegado.
                                En el momento que lo confirmemos, el porcentaje se actualizará y pasará a ser de color rosa.
                            </li>
                            <li className="helpModalTextSeparation">
                                <strong>Participación completa:&#160;</strong>
                                Este tipo de participación solo se puede realizar cuando nadie ha participado aún en el regalo.
                                Si decides esto, también puedes elegir comprarlo tu y traérnoslo a casa, estaremos felices de invitaros!!
                                Si prefieres que lo compremos nosotros, el proceso es el mismo que en la participación parcial.
                            </li>
                        </ul>

                        <div>
                            Cualquier otra duda no dudes en ponerte en contacto con nosotros, mil millones de gracias!
                        </div>
                    </div>
                </WeddingModal>
            </div>
        );
    }
}
