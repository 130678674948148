import React from "react";
import "./../css/modal.css"

interface Props {
    title: String,
    closeButton: boolean;
    isFullScreen?: boolean;
    onClose: () => void
}

export class ModalHeader extends React.Component<Props, {}>{

    render(): React.ReactNode {
        return (
            <div className={this.props.isFullScreen ? "fullScreenHeaderContent":"modalHeaderContent"}>
                <div className="modalHeaderContainer">
                    <span className="modalHeaderTitle">{this.props.title}</span>
                    {renderClose(this.props.closeButton, this.props.onClose)}
                </div>
            </div>
        )
    }
}

function renderClose(displayCloseButton: boolean, onClose: () => void) {
    if (!displayCloseButton) {
        return;
    }
    return (
        <div className="modalHeaderCloseButton" onClick={onClose} />)
}